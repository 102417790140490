import React from "react";
import Nav from "../../components/nav";
import Footer from "../../components/footer";
import RegisterIcon from "../../assets/icons/register";
import Input from "../../components/input";
import { FreeBtn } from "../../components/button";
import JobPosting from "./JobPosting";
import { useGetJobPosting } from "../../hooks/job.hook";

import './styles.css'; 
const jobData = [
  {
    title: "Software Engineer",
    company: "Company XYZ",
    description: "We are looking for a Software Engineer to join our team...",
    requirements: [
      "Bachelor's degree in Computer Science",
      "3+ years of experience in software development",
      "Proficiency in JavaScript and React",
    ],
    benefits: ["Health insurance", "401(k) matching", "Remote work options"],
    image: "https://via.placeholder.com/150", // Dummy image URL
  },
  {
    title: "Product Manager",
    company: "Company ABC",
    description:
      "We are seeking a Product Manager to lead our product development...",
    requirements: [
      "Bachelor's degree in Business or related field",
      "5+ years of experience in product management",
      "Strong analytical skills",
    ],
    benefits: [
      "Competitive salary",
      "Stock options",
      "Health and wellness programs",
    ],
    image: "https://via.placeholder.com/150", // Dummy image URL
  },
  // Add more job postings here
];
  
const Register = () => {
  const { data, isFetching } = useGetJobPosting();
  const jobPostings = data?.data?.data || []; // Default to an empty array if undefined


  console.log('====================================');
  console.log(jobPostings,'111');
  console.log('====================================');
  return (
    <>
      <div className="bg-bg px-7 md:px-14 pt-9">
        <Nav />
        <h1 className="text-3xl font-bold text-center mb-6 text-white">Job</h1>
        {!isFetching && jobPostings.length > 0 ? (
         <div className="job-postings-grid">
         { jobPostings.map((job, index) => (
            <JobPosting
              key={index}
              id={job.id}
              title={job.title}
              company={job.company}
              description={job.description}
              requirements={job.job_requirements}
              benefits={job.benefits}
              image={job.image}
            />
          ))}
         </div>
        ) : (
          <p className="text-center text-gray-500">Loading job postings...</p>
        )}
        <Footer />
      </div>
    </>
  );
};

export default Register;
