import React, { useState } from "react";
import NavItem, { NavBtn } from "../nav-item";
import { useNavigate } from "react-router-dom";
import Menu from "../../assets/icons/menu";
import Cancel from "../../assets/icons/cancel";

const Nav = () => {
  const [openSideNav, setOpenSideNav] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      {openSideNav ? (
        <nav className="fixed top-0 left-0 w-full h-full bg-bg flex justify-center items-center z-50">
          <div
            className="absolute text-sky-blue top-10 left-10"
            onClick={() => setOpenSideNav(false)}
          >
            <Cancel />
          </div>
          <div className="flex flex-col gap-14 items-center">
            {/* <NavItem
              title="HOME"
              link="/"
            /> */}
            <NavItem
              title="JOIN NOW"
              link="/pricing"
            />
            <NavItem
              title="DOWNLOAD"
              link="/download"
            />

            <NavItem
              title="REGISTER"
              link="/register"
            />
            <NavItem
              title="SPEAK NOW"
              link="/speak"
            />
            <NavBtn
              title="LOGIN"
              link="/login"
            />
          </div>
        </nav>
      ) : (
        <nav className="bg-bg flex justify-between items-center px-4 py-4 md:px-7 md:py-5 rounded-xl">
          <div className="flex gap-3 items-center justify-between w-full">
            <h1
              className="text-sky-blue font-[800] text-xl cursor-pointer"
              onClick={() => navigate("/")}
            >
              URGE
            </h1>
            <div
              className="md:hidden text-sky-blue"
              onClick={() => setOpenSideNav(true)}
            >
              <Menu />
            </div>
          </div>

          <div className="hidden md:flex md:gap-14 items-center">
            {/* <NavItem
              title="HOME"
              link="/"
            /> */}
            <NavItem
              title="JOIN NOW"
              link="/pricing"
            />
            <NavItem
              title="DOWNLOAD"
              link="/download"
            />

            <NavItem
              title="REGISTER"
              link="/register"
            />
            <NavItem
              title="SPEAK NOW"
              link="/speak"
            />
            <NavItem
              title="COLLABORATION"
              link="/collab"
            />
            <NavBtn
              title="LOGIN"
              link="/login"
            />
          </div>
        </nav>
      )}
    </>
  );
};

export default Nav;

export const Nav2 = () => {
  const [openSideNav, setOpenSideNav] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      {openSideNav ? (
        <nav className="fixed top-0 left-0 w-full h-full bg-bg flex justify-center items-center z-50">
          <div
            className="absolute text-sky-blue top-10 left-10"
            onClick={() => setOpenSideNav(false)}
          >
            <Cancel />
          </div>
          <div className="flex flex-col gap-14 items-center">
            <NavItem
              title="MY SUBSCRIPTION"
              link="/p/dashboard"
            />
            <NavItem
              title="DOWNLOAD"
              link="/download"
            />
             <NavItem
              title="JOB"
              link="/job"
            />
          </div>
        </nav>
      ) : (
        <nav className="bg-bg flex justify-between items-center px-4 py-4 md:px-7 md:py-5 rounded-xl">
          <div className="flex gap-3 items-center justify-between flex-1">
            <h1
              className="text-sky-blue font-[800] text-xl cursor-pointer"
              onClick={() => navigate("/")}
            >
              URGE
            </h1>
            <div
              className="md:hidden text-sky-blue"
              onClick={() => setOpenSideNav(true)}
            >
              <Menu />
            </div>
          </div>

          <div className="hidden md:flex md:gap-14 items-center">
            <NavItem
              title="MY SUBSCRIPTION"
              link="/p/dashboard"
            />
            <NavItem
              title="DOWNLOAD"
              link="/download"
            />
              <NavItem
              title="JOB"
              link="/job"
            />
          </div>
        </nav>
      )}
    </>
  );
};
