import React, { useState } from 'react';
import ApplicationForm from './ApplicationForm';


const JobPosting = ({  id ,title, company, description, requirements, benefits, image }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="job-posting max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md mb-6">
      <h2 className="text-2xl font-bold mb-2">{title}</h2>
      <p className="text-gray-700 mb-4">{company}</p>
      <div className="mb-4">
        <h3 className="font-semibold">Job Description:</h3>
        <p className="text-gray-600">{description}</p>
      </div>
      <div className="mb-4">
        <h3 className="font-semibold">Requirements:</h3>
        <ul className="list-disc list-inside text-gray-600">
          {requirements}
        </ul>
      </div>
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        onClick={openModal}
      >
        Apply Now
      </button>
      {isModalOpen && <ApplicationForm  id={id} closeModal={closeModal} />}
    </div>
  );
};

export default JobPosting;
